@font-face {
    font-family: 'LDBoldini-Bold';
    src: url('LDBoldini-Bold.eot');
    src: url('LDBoldini-Bold.eot?#iefix') format('embedded-opentype'),
        url('LDBoldini-Bold.woff2') format('woff2'),
        url('LDBoldini-Bold.woff') format('woff'),
        url('LDBoldini-Bold.ttf') format('truetype'),
        url('LDBoldini-Bold.svg#LDBoldini-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LDBoldini-Regular';
    src: url('LDBoldini-Regular.eot');
    src: url('LDBoldini-Regular.eot?#iefix') format('embedded-opentype'),
        url('LDBoldini-Regular.woff2') format('woff2'),
        url('LDBoldini-Regular.woff') format('woff'),
        url('LDBoldini-Regular.ttf') format('truetype'),
        url('LDBoldini-Regular.svg#LDBoldini-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

